import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

// Auth
import {signOut as signOutAct} from '../../../auth/redux/actions';

// components
import Header, {HeaderRow, HeaderTitle} from '../../components/Header/Header';
import HeaderButtons from './components/HeaderButtons';
import LogoContainer from '../../components/LogoContainer/LogoContainer';
import Logo from '../../components/Logo/Logo';
import Logout from '../../../components/Logout/Logout';
import Text from '../../../components/Text/Text';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import Title, {TitleSeparator} from '../../components/Title/Title';
import DepartmentSelection from '../../../department/components/DepartmentSelection/DepartmentSelection';

// images
import LogoPath from '../../../assets/images/porsche-logo-white.png';

// store
import store from '../../../redux/store';
import getItemsFromCart from '../../../lib/getItemsFromCart';

// navItems
import navItems from './NavItems';

// cartPageName
import CartPageName from '../../../pages/CartPage/page';

// Redux
import {connect} from 'react-redux';

class Page extends Component {
  static propTypes = {
    page: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    cartCount: 0,
  };

  componentDidMount() {
    const cartCount = this.countCartQuantity(getItemsFromCart());
    this.setState({
      cartCount,
    });
    store.subscribe(() => {
      const cartCount = this.countCartQuantity(getItemsFromCart());
      this.setState({
        cartCount,
      });
    });
  }

  countCartQuantity = (cartItems) => {
    return cartItems && cartItems.length > 0
      ? cartItems
          .map((item) => (item.cartQuantity ? item.cartQuantity : 0))
          .reduce((prev, next) => prev + next)
      : 0;
  };

  onLogoutClick = () => this.props.dispatch(signOutAct());

  render() {
    const {children, page, history} = this.props;
    const cartCount = this.state.cartCount;
    return (
      <Fragment>
        <Header>
          <HeaderRow>
            <LogoContainer>
              <Logo src={LogoPath} />
            </LogoContainer>
            {/* <Notification>
              Spoštovane stranke, <br />
              obveščamo vas, da so naša skladišča (v Zagrebu, Bratislavi in
              Bukarešti) zaprta od vključno 23. 12. 2024 do 2. 1. 2025. <br />
              Prvi delovni dan v letu 2025 je 3. 1. 2025. <br />
              6.1.2025 je državni praznik na Hrvaškem in Slovaškem, skladišča v
              Zagrebu in Bratislavi bodo zaprta. <br />
              Lepe pozdrave, <br />
              Continental Adria
            </Notification> */}
            {/* <Notification>
              Drage stranke, <br />
              sporočamo, da v naslednjih dneh delamo po spremenjenem delovnem
              času: 29.1.2025 gredo na naklad le spletna naročila. <br />
              Cut-off je ob 12:00. 30.1.2025 so naše pisarne zaprte, tako da ta
              dan nismo dosegljivi in ni nakladov. <br />
              Hvala za vaše razumevanje! <br />
              Ekipa Continental Adria
            </Notification> */}
            <HeaderButtons>
              <DepartmentSelection />
              <Logout onLogout={this.onLogoutClick} />
            </HeaderButtons>
          </HeaderRow>
          <HeaderTitle>
            <Text color="orange">COPS</Text> Online Portal
          </HeaderTitle>
        </Header>
        <TitleHeader>
          {navItems.map((item, index) => (
            <Fragment key={item.id}>
              <Title
                onClick={() => {
                  history.push(item.route());
                }}
                current={page === item.page}
              >
                {item.name} {item.page === CartPageName ? `(${cartCount})` : ''}
              </Title>
              {item.id !== 7 &&
              navItems[index > 0 ? index : 0].page !== page &&
              navItems[index + 1].page !== page ? (
                <TitleSeparator />
              ) : (
                ''
              )}
            </Fragment>
          ))}
        </TitleHeader>
        {children}
      </Fragment>
    );
  }
}

export default withRouter(connect()(Page));
