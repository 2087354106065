import doc1 from '../../assets/documents/Continental_zimske_202324.pdf';
import doc2 from '../../assets/documents/Semperit_2025.pdf';
import doc3 from '../../assets/documents/Barum_letne_2025.pdf';
import doc4 from '../../assets/documents/Continental_gume_2025_celoletne.pdf';
import doc5 from '../../assets/documents/Continental_letne_2025.pdf';

const documents = [
  {
    name: 'Continental (zima)',
    url: doc1,
    type: 'pdf',
  },
  {
    name: 'Continental (celoletne)',
    url: doc4,
    type: 'pdf',
  },
  {
    name: 'Continental (letne)',
    url: doc5,
    type: 'pdf',
  },
  {
    name: 'Semperit',
    url: doc2,
    type: 'pdf',
  },
  {
    name: 'Barum',
    url: doc3,
    type: 'pdf',
  },
];

export default documents;
