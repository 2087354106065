import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Icon from './components/Icon';
import DateText from './components/DateText';
import PriceContainer from './components/PriceContainer';
import DataContainer from './components/DataContainer';
import Data from './components/Data';
import Label, {IconLabel, Price, PriceLabel} from './components/Label';
// import PriceText from './components/PriceText';

// consts
import doc from '../../assets/documents/jamstvenizapisnik-11052018.pdf';

//lib
import DeliveryDate from './components/DeliveryDate/DeliveryDate.component';

class ExtendedTableRow extends Component {
  state = {
    showNetPrice: false,
  };
  static propTypes = {
    netPrice: PropTypes.number,
    inquiryError: PropTypes.string,
    discountGrossPrice: PropTypes.number,
    availabilityDates: PropTypes.array,
    ean: PropTypes.string,
    load: PropTypes.string,
    depth: PropTypes.number,
    onRequestedDeliveryDateChange: PropTypes.func,
    tyre: PropTypes.object,
  };

  downloadDocument = (url) => {
    window.open(url, '_blank');
  };

  showNetPriceClick = () => {
    this.setState({
      showNetPrice: !this.state.showNetPrice,
    });
  };

  onDateChange = (date) => {
    this.props.onRequestedDeliveryDateChange({date, tyre: this.props.tyre});
  };

  render() {
    const {
      availabilityDates,
      inquiryError,
      netPrice,
      discountGrossPrice,
      ean,
      depth,
    } = this.props;
    const showNetPrice = this.state.showNetPrice;

    return (
      <Container>
        <DataContainer>
          <Label>Predviden datum nabave</Label>
          <div
            style={{
              display: 'flex',
            }}
          >
            {availabilityDates === undefined && (
              <Icon className={`mdi mdi-loading mdi-spin`} />
            )}
            {availabilityDates && availabilityDates.length === 1 && (
              // <ShowOneDeliveryDate
              //   availabilityDate={availabilityDates[0].deliveryDate}
              //   inquiryError={inquiryError}
              // />
              <DateText valid={availabilityDates[0].deliveryDate}>
                <DeliveryDate
                  availableDate={availabilityDates[0].deliveryDate}
                  minimumDate={this.props.tyre.minimumDate}
                  inquiryError={inquiryError}
                  onDateSelected={this.onDateChange}
                />
              </DateText>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {availabilityDates &&
                availabilityDates.length > 1 &&
                availabilityDates.map((availabilityDate) => (
                  <DateText
                    key={availabilityDate.deliveryDate}
                    valid={undefined}
                  >
                    {availabilityDate.availableQuantity} -{' '}
                    {availabilityDate.deliveryDate}
                  </DateText>
                ))}
            </div>
          </div>
        </DataContainer>
        <DataContainer>
          <Label>EAN</Label>
          <Data>{ean}</Data>
        </DataContainer>
        <DataContainer>
          <Label>Dopolnilno jamstvo</Label>
          <IconLabel
            onClick={() => {
              this.downloadDocument(doc);
            }}
          >
            <i className="mdi mdi-file-pdf-outline" />
          </IconLabel>
        </DataContainer>
        <DataContainer>
          <Label>Globina profila</Label>
          <Data>{depth}</Data>
        </DataContainer>
        <PriceContainer>
          <PriceLabel>
            Akcijska cena: <Price>{discountGrossPrice} €</Price>
          </PriceLabel>
          {showNetPrice ? (
            <PriceLabel clickable onClick={() => this.showNetPriceClick()}>
              Nabavna cena: <Price>{netPrice} €</Price>
            </PriceLabel>
          ) : (
            <PriceLabel clickable onClick={() => this.showNetPriceClick()}>
              Ostali podatki
            </PriceLabel>
          )}
        </PriceContainer>
      </Container>
    );
  }
}

// const ShowOneDeliveryDate = ({availabilityDate, inquiryError}) => (
//   <div>
//     <Icon
//       valid={availabilityDate}
//       className={`mdi ${
//         availabilityDate === undefined
//           ? 'mdi-loading mdi-spin'
//           : availabilityDate === null
//           ? 'mdi-close'
//           : 'mdi-check'
//       }`}
//     />
//     <DateText valid={availabilityDate}>
//       {showDeliveryDate(inquiryError, availabilityDate)}
//     </DateText>
//   </div>
// );

// const showDeliveryDate = (inquiryError, deliveryDate) => {
//   const error = ediErrorCodeHandler(inquiryError);
//   if (error === null || error === undefined) {
//     return deliveryDate === null ? 'Ni na zalogi' : deliveryDate;
//   } else {
//     return error;
//   }
// };

export default ExtendedTableRow;
